import { lazyDefine } from '@github/catalyst';
lazyDefine({
    'animated-image': () => import('../../components/accessibility/animated-image-element.ts'),
  'launch-code': () => import('../../components/account_verifications/launch-code-element.ts'),
  'actions-caches-filter': () => import('../../components/actions/actions-caches-filter-element.ts'),
  'actions-workflow-filter': () => import('../../components/actions/actions-workflow-filter-element.ts'),
  'actions-workflow-list': () => import('../../components/actions/actions-workflow-list-element.ts'),
  'variable-value': () => import('../../components/actions/variables/variable-value-element.ts'),
  'variables-input': () => import('../../components/actions/variables/variables-input-element.ts'),
  'variables-pagination': () => import('../../components/actions/variables/variables-pagination-element.ts'),
  'cvss-calculator': () => import('../../components/advisories/cvss-calculator-element.ts'),
  'cvss-calculator-metric': () => import('../../components/advisories/cvss-calculator-metric-element.ts'),
  'metric-selection': () => import('../../components/advisories/metric-selection-element.ts'),
  'severity-calculator': () => import('../../components/advisories/severity-calculator-element.ts'),
  'severity-score': () => import('../../components/advisories/severity-score-element.ts'),
  'severity-selection': () => import('../../components/advisories/severity-selection-element.ts'),
  'severity-selection-next': () => import('../../components/advisories/severity-selection-next-element.ts'),
  'severity-tracking': () => import('../../components/advisories/severity-tracking-element.ts'),
  'webauthn-status': () => import('../../components/behaviors/webauthn-status-element.ts'),
  'downgrade-dialog': () => import('../../components/billing/settings/downgrade-dialog-element.ts'),
  'manage-subscription': () => import('../../components/billing/settings/upgrade/manage-subscription-element.ts'),
  'pending-cycle-changes-component': () => import('../../components/billing/stafftools/pending-cycle-changes-component-element.ts'),
  'create-branch': () => import('../../components/branch/create-branch-element.ts'),
  'create-repo-from-selector': () => import('../../components/branch/create-repo-from-selector-element.ts'),
  'select-all': () => import('../../components/businesses/people/select-all-element.ts'),
  'close-reason-selector': () => import('../../components/closables/buttons/close-reason-selector-element.ts'),
  'reopen-reason-selector': () => import('../../components/closables/buttons/reopen-reason-selector-element.ts'),
  'alert-dismissal-details': () => import('../../components/code_scanning/alert-dismissal-details-element.ts'),
  'code-scanning-alert-filter': () => import('../../components/code_scanning/code-scanning-alert-filter-element.ts'),
  'pretty-cron': () => import('../../components/code_scanning/pretty-cron-element.ts'),
  'timeout-content': () => import('../../components/code_scanning/timeout-content-element.ts'),
  'message-list': () => import('../../components/code_scanning/tool_status/message-list-element.ts'),
  'sku-list': () => import('../../components/codespaces/advanced_options/sku-list-element.ts'),
  'create-button': () => import('../../components/codespaces/create-button-element.ts'),
  'editor-forwarder': () => import('../../components/codespaces/editor-forwarder-element.ts'),
  'command-palette-page': () => import('../../components/command_palette/command-palette-page-element.ts'),
  'command-palette-page-stack': () => import('../../components/command_palette/command-palette-page-stack-element.ts'),
  'feed-post': () => import('../../components/conduit/feed-post-element.ts'),
  'copilot-signup-choose-plan-type': () => import('../../components/copilot/copilot-signup-choose-plan-type-element.ts'),
  'copilot-business-signup-seat-management': () => import('../../components/copilot/seat_management/copilot-business-signup-seat-management-element.ts'),
  'loading-context': () => import('../../components/dashboard/loading-context-element.ts'),
  'portal-fragment': () => import('../../components/dashboard/portal-fragment-element.ts'),
  'query-search': () => import('../../components/dashboard/query-search-element.ts'),
  'dependabot-alert-dismissal': () => import('../../components/dependabot_alerts/dependabot-alert-dismissal-element.ts'),
  'dependabot-alert-load-all': () => import('../../components/dependabot_alerts/dependabot-alert-load-all-element.ts'),
  'dependabot-alert-row': () => import('../../components/dependabot_alerts/dependabot-alert-row-element.ts'),
  'dependabot-alert-table-header': () => import('../../components/dependabot_alerts/dependabot-alert-table-header-element.ts'),
  'dependabot-updates-paused': () => import('../../components/dependabot/dependabot-updates-paused-element.ts'),
  'deferred-diff-lines': () => import('../../components/diffs/deferred-diff-lines-element.ts'),
  'edit-history': () => import('../../components/discussions/edit-history-element.ts'),
  'conduit-profile-feed-visibility': () => import('../../components/feed/conduit-profile-feed-visibility-element.ts'),
  'readme-toc': () => import('../../components/files/readme-toc-element.ts'),
  'delayed-loading': () => import('../../components/github/delayed-loading-element.ts'),
  'remote-pagination': () => import('../../components/github/remote-pagination-element.ts'),
  'dialog-hydro': () => import('../../components/hydro/dialog-hydro-element.ts'),
  'track-view': () => import('../../components/hydro/track-view-element.ts'),
  'development-menu': () => import('../../components/issues/references/development-menu-element.ts'),
  'load-versions': () => import('../../components/marketplace/load-versions-element.ts'),
  'math-renderer': () => import('../../components/mathjax/math-renderer-element.ts'),
  'memex-project-picker': () => import('../../components/memex/memex-project-picker-element.ts'),
  'memex-project-picker-panel': () => import('../../components/memex/memex-project-picker-panel-element.ts'),
  'memex-project-picker-interstitial': () => import('../../components/memex/project_list/memex-project-picker-interstitial-element.ts'),
  'memex-project-picker-unlink': () => import('../../components/memex/project_list/memex-project-picker-unlink-element.ts'),
  'project-buttons-list': () => import('../../components/memex/project_list/project-buttons-list-element.ts'),
  'navigation-list': () => import('../../components/navigation/navigation-list-element.ts'),
  'notification-shelf-watcher': () => import('../../components/notifications/notification-shelf-watcher-element.ts'),
  'feature-request': () => import('../../components/organizations/member_requests/feature-request-element.ts'),
  'allowed-values-input': () => import('../../components/organizations/settings/codespaces_policy_form/constraint_row/allowed-values-input-element.ts'),
  'host-setup': () => import('../../components/organizations/settings/codespaces_policy_form/constraint_row/host-setup-element.ts'),
  'max-value': () => import('../../components/organizations/settings/codespaces_policy_form/constraint_row/max-value-element.ts'),
  'codespaces-policy-form': () => import('../../components/organizations/settings/codespaces-policy-form-element.ts'),
  'private-registry-form': () => import('../../components/organizations/settings/private-registry-form-element.ts'),
  'repository-selection-input': () => import('../../components/packages/repository-selection-input-element.ts'),
  'experimental-action-menu': () => import('../../components/primer/action_menu/experimental-action-menu-element.ts'),
  'select-panel': () => import('../../components/primer/experimental/select-panel-element.ts'),
  'internal-nav-list-group': () => import('../../components/primer/experimental/side_panel/nav_list/internal-nav-list-group-element.ts'),
  'split-page-layout': () => import('../../components/primer/experimental/split-page-layout-element.ts'),
  'toggle-switch': () => import('../../components/primer/experimental/toggle-switch-element.ts'),
  'lazy-load-section': () => import('../../components/primer/navigation_list/lazy-load-section-element.ts'),
  'profile-timezone': () => import('../../components/profiles/profile-timezone-element.ts'),
  'comment-actions': () => import('../../components/pull_requests/comment-actions-element.ts'),
  'copilot-marketing-popover': () => import('../../components/pull_requests/copilot-marketing-popover-element.ts'),
  'file-filter': () => import('../../components/pull_requests/file_tree/file-filter-element.ts'),
  'file-tree': () => import('../../components/pull_requests/file_tree/file-tree-element.ts'),
  'file-tree-toggle': () => import('../../components/pull_requests/file_tree/file-tree-toggle-element.ts'),
  'reactions-menu': () => import('../../components/reactions/reactions-menu-element.ts'),
  'pin-organization-repo': () => import('../../components/repositories/pin-organization-repo-element.ts'),
  'custom-scopes': () => import('../../components/search/custom-scopes-element.ts'),
  'feature-preview-auto-enroll': () => import('../../components/search/feature-preview-auto-enroll-element.ts'),
  'qbsearch-input': () => import('../../components/search/qbsearch-input-element.ts'),
  'alert-dismissal': () => import('../../components/secret_scanning/alert_centric_view/alert-dismissal-element.ts'),
  'preview-announcement-button': () => import('../../components/settings/messages/preview-announcement-button-element.ts'),
  'recovery-codes': () => import('../../components/settings/recovery-codes-element.ts'),
  'project-picker': () => import('../../components/sidebar/project-picker-element.ts'),
  'deferred-side-panel': () => import('../../components/site/header/deferred-side-panel-element.ts'),
  'notification-indicator': () => import('../../components/site/header/notification-indicator-element.ts'),
  'user-drawer-side-panel': () => import('../../components/site/header/user-drawer-side-panel-element.ts'),
  'slash-command-toolbar-button': () => import('../../components/slash_commands/slash-command-toolbar-button-element.ts'),
  'featured-work': () => import('../../components/sponsors/dashboard/featured-work-element.ts'),
  'sponsors-account-switcher': () => import('../../components/sponsors/sponsors-account-switcher-element.ts'),
  'variant-menu-item': () => import('../../components/stafftools/azure_exp/variant-menu-item-element.ts'),
  'metered-billing-settings-component': () => import('../../components/stafftools/billing/businesses/metered-billing-settings-component-element.ts'),
  'billing-transaction-component': () => import('../../components/stafftools/billing/history/billing-transaction-component-element.ts'),
  'invoice-download': () => import('../../components/stafftools/billing/history/invoice-download-element.ts'),
  'payment-history': () => import('../../components/stafftools/billing/history/payment-history-element.ts'),
  'sponsorships-tabs': () => import('../../components/stafftools/billing/sponsorships-tabs-element.ts'),
  'bundle-size-stats': () => import('../../components/stafftools/bundle-size-stats/bundle-size-stats-element.ts'),
  'datahpc-staffbar': () => import('../../components/stafftools/data_hpc/datahpc-staffbar-element.ts'),
  'react-profiling-toggle': () => import('../../components/stafftools/react/react-profiling-toggle-element.ts'),
  'react-query-devtools': () => import('../../components/stafftools/react/react-query-devtools-element.ts'),
  'react-staffbar': () => import('../../components/stafftools/react/react-staffbar-element.ts'),
  'soft-nav-staffbar': () => import('../../components/stafftools/soft_nav/soft-nav-staffbar-element.ts'),
  'soft-nav-staffbar-preview': () => import('../../components/stafftools/soft_nav/soft-nav-staffbar-preview-element.ts'),
  'stafftools-invoiced-sponsorship-payment-options': () => import('../../components/stafftools/sponsors/invoiced/stafftools-invoiced-sponsorship-payment-options-element.ts'),
  'suggestions-collapsible': () => import('../../components/suggestions/suggestions-collapsible-element.ts'),
  'themed-picture': () => import('../../components/themed_pictures/themed-picture-element.ts'),
  'tasklist-block-add-tasklist': () => import('../../components/tracking_blocks/tasklist-block-add-tasklist-element.ts'),
  'tasklist-block-title': () => import('../../components/tracking_blocks/tasklist-block-title-element.ts'),
  'tracking-block': () => import('../../components/tracking_blocks/tracking-block-element.ts'),
  'tracking-block-omnibar': () => import('../../components/tracking_blocks/tracking-block-omnibar-element.ts'),
  'two-factor-fallback-sms-config-toggle': () => import('../../components/users/settings/two-factor-fallback-sms-config-toggle-element.ts'),
  'two-factor-inline-expander': () => import('../../components/users/settings/two-factor-inline-expander-element.ts'),
  'actions-announceable-search-result-summary': () => import('@github-ui/actions-announceable-search-result-summary-element'),
  'announce-live': () => import('@github-ui/announce-live-element'),
  'billing-country-and-region-selection': () => import('@github-ui/billing-country-and-region-selection-element'),
  'business-shipping-information': () => import('@github-ui/business-shipping-information-element'),
  'business-use-billing-information-for-shipping': () => import('@github-ui/business-use-billing-information-for-shipping-element'),
  'change-password': () => import('@github-ui/change-password-element'),
  'codespace-share-dialog': () => import('@github-ui/codespace-share-dialog-element'),
  'cohort-widget': () => import('@github-ui/cohort-widget-element'),
  'cookie-consent-link': () => import('@github-ui/cookie-consent-link-element'),
  'copilot-business-settings': () => import('@github-ui/copilot-business-settings-element'),
  'copilot-dashboard-entrypoint': () => import('@github-ui/copilot-dashboard-entrypoint-element'),
  'copilot-mixed-license-orgs-list': () => import('@github-ui/copilot-mixed-license-orgs-list-element'),
  'copilot-plan-account-select': () => import('@github-ui/copilot-plan-account-select-element'),
  'copilot-plan-select-dialog': () => import('@github-ui/copilot-plan-select-dialog-element'),
  'copilot-purchase-intent-form': () => import('@github-ui/copilot-purchase-intent-form-element'),
  'copilot-review-feedback': () => import('@github-ui/copilot-review-feedback-element'),
  'copilot-summarize-banner': () => import('@github-ui/copilot-summarize-banner-element'),
  'copilot-summarize-banner-staffbar-controls': () => import('@github-ui/copilot-summarize-banner-staffbar-controls-element'),
  'copilot-text-completion': () => import('@github-ui/copilot-text-completion-element'),
  'copilot-user-settings': () => import('@github-ui/copilot-user-settings-element'),
  'copy-project': () => import('@github-ui/copy-project-element'),
  'deploy-key-policy-warning': () => import('@github-ui/deploy-key-policy-warning-element'),
  'discussion-spotlight-container': () => import('@github-ui/discussion-spotlight-container-element'),
  'edit-hook-secret': () => import('@github-ui/edit-hook-secret-element'),
  'emu-contribution-blocked-hint': () => import('@github-ui/emu-contribution-blocked-hint-element'),
  'expandable-role-row': () => import('@github-ui/expandable-role-row-element'),
  'experimental-action-list': () => import('@github-ui/experimental-action-list-element'),
  'fgp-search': () => import('@github-ui/fgp-search-element'),
  'flywheel-return-to-tour': () => import('@github-ui/flywheel-return-to-tour-element'),
  'fullstory-capture': () => import('@github-ui/fullstory-capture-element'),
  'ghcc-consent': () => import('@github-ui/ghcc-consent-element'),
  'inline-security-checkup-notice': () => import('@github-ui/inline-security-checkup-notice-element'),
  'input-page-refresh': () => import('@github-ui/input-page-refresh-element'),
  'integration-agent-form': () => import('@github-ui/integration-agent-form-element'),
  'issue-create': () => import('@github-ui/issue-create-element'),
  'jump-to': () => import('@github-ui/jump-to-element'),
  'markdown-accessiblity-table': () => import('@github-ui/markdown-accessiblity-table-element'),
  'marketplace-security-compliance-trader-self-certification': () => import('@github-ui/marketplace-security-compliance-trader-self-certification-element'),
  'microsoft-analytics': () => import('@github-ui/microsoft-analytics-element'),
  'microsoft-analytics-event': () => import('@github-ui/microsoft-analytics-event-element'),
  'query-builder': () => import('@github-ui/query-builder-element'),
  'react-partial-anchor': () => import('@github-ui/react-partial-anchor-element'),
  'security-analysis-ghas': () => import('@github-ui/security-analysis-ghas-element'),
  'share-button-form-submit-handler': () => import('@github-ui/share-button-form-submit-handler-element'),
  'show-dialog-on-load': () => import('@github-ui/show-dialog-on-load-element'),
  'sidebar-pinned-topics': () => import('@github-ui/sidebar-pinned-topics-element'),
  'site-header-logged-in-user-menu': () => import('@github-ui/site-header-logged-in-user-menu-element'),
  'stafftools-topics-table': () => import('@github-ui/stafftools-topics-table-element'),
  'task-component': () => import('@github-ui/task-component-element'),
  'team-sync-okta-config-form': () => import('@github-ui/team-sync-okta-config-form-element'),
  'topic-feeds-toast-trigger': () => import('@github-ui/topic-feeds-toast-trigger-element'),
  'updatable-content': () => import('@github-ui/updatable-content-element'),
  'visible-password': () => import('@github-ui/visible-password-element'),
  'webauthn-get': () => import('@github-ui/webauthn-get-element'),
  'webauthn-subtle': () => import('@github-ui/webauthn-subtle-element'), 'profile-pins': ()=>import('./github/profile/profile-pins-element'),
    'emoji-picker': ()=>import('./github/emoji-picker-element'),
    'insights-query': ()=>import('./github/insights/insights-query'),
    'remote-clipboard-copy': ()=>import('./github/behaviors/remote-clipboard-copy'),
    'series-table': ()=>import('./github/insights/series-table'),
    'line-chart': ()=>import('./github/insights/line-chart'),
    'bar-chart': ()=>import('./github/insights/bar-chart'),
    'column-chart': ()=>import('./github/insights/column-chart'),
    'stacked-area-chart': ()=>import('./github/insights/stacked-area-chart'),
    'hero-stat': ()=>import('./github/insights/hero-stat'),
    'pulse-authors-graph': ()=>import('./github/graphs/pulse-authors-graph-element'),
    'community-contributions-graph': ()=>import('./github/graphs/community-contributions'),
    'discussion-page-views-graph': ()=>import('./github/graphs/discussion-page-views'),
    'discussions-daily-contributors': ()=>import('./github/graphs/discussions-daily-contributors'),
    'discussions-new-contributors': ()=>import('./github/graphs/discussions-new-contributors'),
    'code-frequency-graph': ()=>import('./github/graphs/code-frequency-graph-element'),
    'contributors-graph': ()=>import('./github/graphs/contributors-graph-element'),
    'traffic-clones-graph': ()=>import('./github/graphs/traffic-clones-graph-element'),
    'traffic-visitors-graph': ()=>import('./github/graphs/traffic-visitors-graph-element'),
    'commit-activity-graph': ()=>import('./github/graphs/commit-activity-graph-element'),
    'marketplace-insights-graph': ()=>import('./github/graphs/marketplace-insights-graph-element'),
    'user-sessions-map': ()=>import('./github/settings/user-sessions-map-element'),
    'reload-after-polling': ()=>import('./github/behaviors/reload-after-polling-element'),
    'query-builder': ()=>import('@github-ui/query-builder-element'),
    'package-dependencies-security-graph': ()=>import('./github/graphs/package-dependencies-security-graph-element'),
    '.js-sub-dependencies': ()=>import('./github/dependencies'),
    'network-graph': ()=>import('./github/graphs/network-graph-element'),
    'inline-machine-translation': ()=>import('./github/localization/inline-machine-translation-element'),
    'custom-patterns-filter': ()=>import('./github/secret-scanning/custom-patterns-filter')
});
